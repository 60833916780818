import React from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ContactForm from "../components/form/contact";
import Map from "../components/Map";
gsap.registerPlugin(ScrollTrigger);

const Contact = () => {
  return (
    <div className="w-full flex flex-col items-center justify-center">
      <section className="w-full flex flex-col items-center justify-center">
        <div className="w-full flex flex-col items-start justify-between max-w-screen-2xl pt-16 sm:pt-32 px-8 box-border gap-16">
          <div className="w-full flex flex-col gap-12">
            <h1 className="text-4xl sm:text-6xl md:text-7xl lg:text-8xl text-black">
              Talk to an agent.
            </h1>
            <Map />
          </div>
          <div className="w-full flex flex-col md:flex-row items-start justify-between">
            <div className="w-full md:pr-[5rem] lg:pr-[10rem] xl:pr-[15rem] flex flex-col justify-between">
              <p className="text-2xl text-black mb-12">
                Rothstein Insurance Services, Inc. has local offices in Ventura,
                CA and Oahu HI. We are an independent insurance brokerage with
                over 30 years of experience and are trusted risk advisors of
                clients both locally and nationwide.
              </p>
              <div>
                <h3 className="text-4xl font-semibold text-blue mb-12">
                  Give us a call.
                </h3>
                <div className="flex flex-col sm:flex-row md:flex-col gap-12 mb-[2rem] ">
                  <div>
                    <p className="text-2xl text-black">Ventura, CA</p>
                    <p className="text-2xl text-black">(805) 648-6595 x102</p>
                  </div>
                  <div>
                    <p className="text-2xl text-black">Honolulu, HI</p>
                    <p className="text-2xl text-black">(808) 339-3032</p>
                  </div>
                </div>
              </div>
            </div>
            <div id="contact-form" className="w-full md:max-w-[49vw]">
              <h5 className="text-xl text-darkerGrey font-bold mb-6">
                Send our team a message.
              </h5>
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
