import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./map.scss";
import { Link } from "../links";
import { StaticImage } from "gatsby-plugin-image";
gsap.registerPlugin(ScrollTrigger);

const Map = () => {
  const venturaRef = useRef(null);
  const oahuRef = useRef(null);
  const animationsRef = useRef([]);
  useEffect(() => {
    const createAnimation = (ref, startY, endY) => {
      if (ref.current) {
        gsap.set(ref.current, { y: startY });

        const anim = gsap.fromTo(
          ref.current,
          { y: startY },
          {
            y: endY,
            duration: 1,
            scrollTrigger: {
              trigger: ref.current,
              start: "top bottom",
              end: "bottom top",
              scrub: true,
            },
          }
        );
        animationsRef.current.push(anim);
      }
    };

    createAnimation(venturaRef, -100, -300);
    createAnimation(oahuRef, -100, -300);

    return () => {
      animationsRef.current.forEach((anim) => {
        anim.scrollTrigger?.kill();
        anim.kill();
      });
      animationsRef.current = [];
    };
  }, []);
  return (
    <div className="w-full flex flex-col sm:flex-row items-center justify-between gap-12 box-border">
      <div className="flex flex-col box-border ri-anim-left sm:overflow-hidden w-full">
        <h3 className="text-3xl font-semibold text-blue mb-6">Ventura, CA</h3>
        <Link isAnchor to="#contact-form">
          <div className="ri-contact__header__image group relative">
            <h4 className="text-2xl text-blue font-semibold absolute z-10 opacity-0 group-hover:opacity-100 transition-all duration-300 ease-out top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 group-hover:text-4xl">
              Click to get in touch.
            </h4>
            <div
              ref={venturaRef}
              className="ri-contact__header__image__wrapper group-hover:brightness-90 transition-all duration-300 ease-out"
            >
              <StaticImage
                src="../../images/contact/ventura-map2.png"
                alt="Ventura Map"
                placeholder="blurred"
                layout="fullWidth"
              />
            </div>
          </div>
        </Link>
        <h5 className="text-lg text-blue font-semibold">
          1591 Spinnaker Drive, Suite 205 Ventura, CA 93001
        </h5>
      </div>
      <div className="flex flex-col box-border ri-anim-right sm:overflow-hidden w-full">
        <h3 className="text-3xl font-semibold text-blue mb-6">Honolulu, HI</h3>
        <Link isAnchor to="#contact-form">
          <div className="ri-contact__header__image group relative">
            <h4 className="text-2xl text-blue font-semibold absolute z-10 opacity-0 group-hover:opacity-100 transition-all duration-300 ease-out top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 group-hover:text-4xl">
              Click to get in touch.
            </h4>
            <div
              ref={oahuRef}
              className="ri-contact__header__image__wrapper group-hover:brightness-90 transition-all duration-300 ease-out"
            >
              <StaticImage
                src="../../images/contact/honolulu-map3.png"
                alt="Oahu Map"
                placeholder="blurred"
                layout="fullWidth"
              />
            </div>
          </div>
        </Link>
        <h5 className="text-lg text-blue font-semibold">
          330 Saratoga Road, Unit 89663 Honolulu, HI 96815
        </h5>
      </div>
    </div>
  );
};

export default Map;
